import React, { useState } from "react"
import Select from "react-select"
import { ReactComponent as Arrow } from "./down-arrow.svg"
import {
  SelectContainer,
  ArrowContainer,
  SingleValueContainer,
  // Placeholder,
} from "./style"

const SelectInput = ({
  placeholder,
  options,
  debug,
  selectedFilters,
  onChange,
  name,
  type,
  className,
}) => {
  const [value, setValue] = useState(null)
  const customSingleValue = () => (
    <SingleValueContainer>
      <span>{value?.label || placeholder}</span>
    </SingleValueContainer>
  )

  const customDropdownIndicator = (props) => {
    return (
      <ArrowContainer open={props.selectProps.menuIsOpen}>
        <Arrow />
      </ArrowContainer>
    )
  }

  const handleChange = (e) => {
    setValue(e)
    onChange(e, {name: name})
  }
  return (
    <SelectContainer className={className}>
      <Select
        menuIsOpen={debug}
        classNamePrefix="basic"
        isSearchable={false}
        // autoFocus={false}
        options={options}
        value={selectedFilters}
        components={{
          SingleValue: customSingleValue,
          DropdownIndicator: customDropdownIndicator,
          Placeholder: customSingleValue,
        }}
        type={type || "select"}
        onChange={(e) => handleChange(e)}
        name={name}
        id={name}
      />
    </SelectContainer>
  )
}

export default SelectInput

import styled from "styled-components"

export const SelectContainer = styled.div`
  width: calc(100% - 48px);
  margin: 0 auto;
  z-index: 10;
  position: relative;

  .css-1hwfws3 {
    padding: 0;
  }

  .basic__control {
    box-shadow: 0;
    outline: none;
    background: #ffffff;
    border: 1px solid #c4cfd7;
    border-radius: 5px;
    height: 50px;
    cursor: pointer;
    padding: 0 15px;

    :hover {
      border: 1px solid ${(props) => props.theme.teal};
    }

    &--menu-is-open {
      border-radius: 5px 5px 0 0;
      box-shadow: none;
      border: 1px solid #c4cfd7;
      border-color: #c4cfd7;
    }

    &--is-focused {
      box-shadow: none;
      border: 1px solid #c4cfd7;
      border-color: #c4cfd7;
    }
  }

  .basic__value-container {
    padding: 0;
    border-radius: 0;
  }

  .basic__indicator-separator {
    display: none;
  }
  .basic__menu {
    margin-top: 0;
    border-radius: 0 0 4px 4px;
    width: calc(100% - 2px);
    margin-left: 1px;
  }
  .basic__menu-list {
    padding-top: 0;
    padding-bottom: 0;
    border-radius: inherit;
  }

  .basic__option {
    font-weight: normal;
    color: #2e3a48;
    font-size: 14px;
    padding: 15px 15px;

    :active {
      background-color: #ddd;
    }
  }
  .basic__option--is-focused {
    background-color: #eee;
    transition: 90ms;
    cursor: pointer;
  }

  .basic__option--is-selected {
    background-color: ${(props) => props.theme.teal};
    color: white;
  }
`

export const ArrowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 16px;
    height: 16px;
    transform: rotateX(${(props) => (props.open ? "180deg" : "0")});
    transition: ${(props) => (props.open ? "300ms" : "300ms")};
    path {
      stroke: teal;
    }
  }
`

export const SingleValueContainer = styled.div`
  span {
    font-weight: normal;
    color: #2e3a48;
    font-size: 14px;
  }
`

export const Placeholder = styled(SingleValueContainer)`
  background: red;
`
